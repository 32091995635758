import https from "./https";
import store from "@/store";

const badgemap = {
  async getEvent(eventCode) {
    let response = {}
    const organization = store.state.liffGeneral.orgCode;
    if (eventCode) {
      response = await https.get(`${organization}/liff/badgemap/get-event?event_code=${eventCode}`);
    } else {
      response = await https.get(`${organization}/liff/badgemap/get-event`);
    }
    return response.data
  },
};

export default badgemap;
