<template>
  <div>
    <loading v-if="isLoading"></loading>
    <div class="container pb-5">
      <div class="map position-relative">
        <img src="./images/hero.jpg" class="img-fluid" />
        <template v-for="tag in tags">
          <img :key="tag" :src="imgPath(tag)" class="img-fluid badge-img" />
        </template>
      </div>
      <div class="body text-center">
        <div class="font-weight-bold">
          <div>已經成功探索過<span class="number mx-2">{{ collectCount }}</span>大節慶</div>
          <div>集滿{{ totalCount }}枚徽章就能抽</div>
          <div>「隨興聽Bar藍牙音箱」</div>
          <div>繼續加油吧！</div>
        </div>

        <img src="./images/bar.png" class="img-fluid mt-3" />

        <a class="btn button" v-if="isCompleted" @click="gotToCompletedUrl()">
          <img src="./images/button.png" class="img-fluid mt-3" />
        </a>

        <a href="#" class="btn button disabled" v-if="!isCompleted">
          <img src="./images/button-disabled.png" class="img-fluid mt-3" />
        </a>

        <div class="info text-white text-left px-4 py-3 m-4">
          <h5 class=" text-center font-weight-bold" v-html="extraTitleText"></h5>
          <div v-html="extraDescText"></div>
        </div>


        <div class="fixed-bottom"><img src="./images/warning.jpg" class="img-fluid" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import badgemapApi from "@/apis/liff/v2/badgemap";

export default {
  data() {
    return {
      eventCode: "",
      badgemapInfo: {},
      isLoading: true,
    };
  },

  created() {
    this.eventCode = this.$route.params.event_code
  },

  mounted() {
    this.init();
  },

  computed: {
    tags() {
      return this.badgemapInfo?.tags ?? []
    },
    isCompleted() {
      return this.badgemapInfo?.is_completed ?? false
    },
    badgemapCompleted() {
      return this.badgemapInfo?.badgemap_completed ?? []
    },
    collectCount() {
      return this.badgemapInfo?.collect_count ?? 0
    },
    totalCount() {
      return this.badgemapInfo?.total_count ?? 8
    },
    extraDescText() {
      return this.badgemapInfo?.extra?.desc ?? '2023 KIRIN淡麗GREEN LABEL「清爽喝 輕盈旅行趣」活動（下稱「本活動」），凡於活動期間內購買台灣麒麟啤酒進口之「KIRIN淡麗GREEN LABEL啤酒」系列商品（詳見後述「活動商品」說明），即可上傳發票至本活動網站兌換KIRIN Points。每罐活動商品均可換取KIRIN Point 1點。KIRIN Point點數可用於兌換本活動抽獎機會。'
    },
    extraTitleText() {
      return this.badgemapInfo?.extra?.title ?? '活動辦法'
    },
  },

  methods: {
    imgPath(tag) {
      if (tag.collected) {
        try {
          return require(`@/pages/Liff/Kirin/images/${tag.id}.png`)
        } catch (error) {
          console.log(error)
          return ""
        }
      }
      return ""
    },
    async init() {
      try {
        const { data } = await badgemapApi.getEvent(this.eventCode)
        this.badgemapInfo = data
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$swal.fire({
          title: error.response.data.message,
          type: "error",
        });
      }
    },
    gotToCompletedUrl() {
      if (!this.isCompleted) {
        return;
      }
      window.location.href = this.badgemapCompleted['button_url']
    }
  }
};
</script>

<style lang="scss">
.container {
  max-width: 750px;
  margin: auto;
  position: relative;
  padding: 0;
  background-color: #ffd000;
}

.map {
  .badge-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.body {
  background: url(images/bg.png) no-repeat;
  background-size: 100%;

  .number {
    font-size: 30px;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  }

  .button {
    max-width: 50%;
  }

  .info {
    background-color: #c5a100;
    border-radius: 1rem;
  }
}
</style>
